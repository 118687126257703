import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Image,
  Text,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import PopUpModal from "../Form/popup_model";

export default function Service({
  imgUrl,
  department,
  name,
  designation,
  description,
  social,
  href,
  id, // Assuming `id` is passed as a prop
}) {
  const {
    isOpen: isBookModalOpen,
    onOpen: onBookModalOpen,
    onClose: onBookModalClose,
  } = useDisclosure();

  return (
    <Box className="cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden">
      <Box className="cs_member_img">
        <Link to={href} className="d-block">
          <Image src={imgUrl} alt="Service" />
        </Link>
      </Box>
      <Box className="cs_team_meta cs_white_bg">
        <Box>
          <Heading as="h3" size="lg" className="cs_member_name cs_fs_32">
            <Link to={href}>{name}</Link>
          </Heading>
          <Text className="cs_member_description">{description}</Text>
        </Box>
        <Flex justifyContent="space-between" pb="10px">
          <button className="cs_btn cs_style_1" onClick={onBookModalOpen}>
            <span>Book Service</span>
          </button>
          <Link
            className="cs_btn cs_style_1"
            to={href}
            style={{ color: "white" }}
          >
            <span>View Details</span>
          </Link>
        </Flex>
      </Box>
      <PopUpModal
        isBookModalOpen={isBookModalOpen}
        onBookModalClose={onBookModalClose}
        name={name}
        id={id}
      />
    </Box>
  );
}
