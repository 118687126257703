import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import Section from "../Section";
import GallerySectionStyle2 from "../Section/GallerySection/GallerySectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
const galleryData = [
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_2_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_3_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_2_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_3_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_2_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_3_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
];

export default function Gallery() {
  pageTitle("Gallery");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.jpg"
        imgUrl="/images/about/about_food_image.webp"
        title="Gallery"
        subTitle="The special moments of our services"
      />
      <Section
        topMd={170}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <GallerySectionStyle2 data={galleryData} />
      </Section>
      
    </>
  );
}
