import React from "react";
import Spacing from "../../Spacing";
import SectionHeading from "../../SectionHeading";
import ServiceCraousel from "../../Slider/ServiceCraousel";

export default function ServiceSection({ sectionTitle, sectionTitleUp, data }) {
  return (
    <div className="container">
      <div className="position-relative">
        <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
        <Spacing md="72" lg="50" />
        <ServiceCraousel data={data} />
      </div>
    </div>
  );
}
