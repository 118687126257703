import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Image,
  Text,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import List from "../../List";
import ListStyle3 from "../../List/ListStyle3";
import ListStyle2 from "../../List/ListStyle2";
import Spacing from "../../Spacing";
import PopUpModal from "../../Form/popup_model";

export default function ServiceDetailsSection({
  bgUrl,
  imgUrl,
  name,
  department,
  designation,
  description,
  social,
  contactInfo,
  contactInfoHeading,
  degrees,
  degreesHeading,
  experiences,
  experiencesHeading,
  awards,
  awardHeading,
  schedules,
  scheduleHeading,
  id,
}) {
  const {
    isOpen: isBookModalOpen,
    onOpen: onBookModalOpen,
    onClose: onBookModalClose,
  } = useDisclosure();

  return (
    <div className="cs_doctor_details">
      <div
        className="cs_doctor_details_bg cs_bg_filed"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
      />
      <Spacing md="85" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cs_single_doctor overflow-hidden cs_radius_20">
              <img src={imgUrl} alt="Doctor" className="w-100" />
              <h3 className="cs_white_color cs_accent_bg mb-0 text-center cs_semibold cs_fs_24">
                {department}
              </h3>
            </div>

            <Spacing md="94" lg="60" />
            <ListStyle2
              heading={contactInfoHeading}
              iconUrl="/images/icons/schedule.svg"
              data={contactInfo}
            />
            <Spacing md="66" lg="60" />
            {/* <ListStyle3
              heading={scheduleHeading}
              iconUrl="/images/icons/schedule.svg"
              data={schedules}
            /> */}
          </div>

          <div className="col-lg-6 offset-lg-1 position-relative">
            <Spacing md="55" />
            <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>
            <Spacing md="12" />
            <h3 className="cs_semibold cs_fs_24 mb-0">{designation}</h3>
            <Spacing md="32" />
            <p className="mb-0 cs_heading_color">{description}</p>
            <br />
            <Button onClick={onBookModalOpen} className="cs_btn cs_style_1">
              <span>Book Service</span>
            </Button>
            <Spacing md="200" xl="150" lg="80" />
            <List
              heading={experiencesHeading}
              iconUrl="/images/icons/award2.svg"
              data={experiences}
            />
            <Spacing md="70" lg="50" />
            {/* <List
              heading={awardHeading}
              iconUrl="/images/icons/award2.svg"
              data={awards}
            /> */}
          </div>
        </div>
      </div>
      <PopUpModal
        isBookModalOpen={isBookModalOpen}
        onBookModalClose={onBookModalClose}
        name={name}
        id={id}
      />
    </div>
  );
}
