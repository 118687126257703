import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "@emotion/styled";
import axios from "axios";
import Swal from "sweetalert2";

const DatePickerWrapper = styled.div`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem !important;
  font-size: 1rem;
  height: 2.5rem;
  ::placeholder {
    color: #a0aec0;
  }
`;

export default function BookingForm({ close, id, service_name }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      customer_no: "",
      service_type_no: id,
      event_date: new Date(),
      service_status: "pending",
      address: "",
      service_name: service_name,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      customer_no: Yup.string()
        .matches(/^\d+$/, "Must be only digits")
        .min(10, "Must be exactly 10 digits")
        .max(10, "Must be exactly 10 digits")
        .required("Required"),
      address: Yup.string().required("Required"),
      event_date: Yup.date().required("Required").nullable(),
    }),
    onSubmit: (values) => {
      // console.log(values);
      setIsSubmitting(true);
     // console.log(values);
      axios
        .post("https://api.bramhanavilas.com/service_booking.php", values)
        .then((response) => {
          // console.log("Success:", response.data);
          setIsSubmitting(false);
          close();
          Swal.fire({
            title: "Success!",
            text: "Form submitted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsSubmitting(false);
          close();
          Swal.fire({
            title: "Error!",
            text: "An error occurred while submitting the form.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={formik.touched.name && formik.errors.name}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Enter your name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formik.touched.email && formik.errors.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={formik.touched.customer_no && formik.errors.customer_no}
        >
          <FormLabel htmlFor="customer_no">Phone Number</FormLabel>
          <Input
            id="customer_no"
            name="customer_no"
            type="text"
            placeholder="Enter your phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customer_no}
          />
          <FormErrorMessage>{formik.errors.customer_no}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={formik.touched.address && formik.errors.address}
        >
          <FormLabel htmlFor="address">Address</FormLabel>
          <Input
            id="address"
            name="address"
            type="text"
            placeholder="Enter your address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={formik.touched.event_date && formik.errors.event_date}
        >
          <FormLabel htmlFor="event_date">Event Date</FormLabel>
          <DatePickerWrapper>
            <StyledDatePicker
              id="event_date"
              name="event_date"
              placeholderText="Select the event date"
              selected={formik.values.event_date}
              onChange={(date) => formik.setFieldValue("event_date", date)}
              onBlur={formik.handleBlur}
              dateFormat="yyyy-MM-dd"
            />
          </DatePickerWrapper>
          <FormErrorMessage>{formik.errors.event_date}</FormErrorMessage>
        </FormControl>

        <Input
          id="service_type_no"
          name="service_type_no"
          type="hidden"
          value={formik.values.service_type_no}
        />
        <Input
          id="service_status"
          name="service_status"
          type="hidden"
          value={formik.values.service_status}
        />

        <Button
          type="submit"
          background="linear-gradient(to right top, #ff9b1a, #e88f24, #d2832a, #bc772f, #a66c32)"
          color="white"
          _hover={{
            background:
              "linear-gradient(to right top, #e88f24, #d2832a, #bc772f, #a66c32, #8c5c2d)",
          }}
        >
          {isSubmitting ? <Spinner size="sm" /> : <span>Submit</span>}
        </Button>
      </VStack>
    </form>
  );
}
