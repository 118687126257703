import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import GallerySection from '../Section/GallerySection';
import { pageTitle } from '../../helpers/PageTitle';
import Spacing from '../Spacing';
const departmentData = [
  {
    title: 'Home Cooking',
    subTitle:
      'Need the expertise of a Brahmin chef in traditional dhoti at your home? We provide skilled cooks to prepare meals right in your kitchen, ensuring a personalized dining experience',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/service',
  },
  {
    title: 'Complete Catering Package',
    subTitle:
      'From sourcing fresh vegetables to bringing the necessary cookware, we manage it all. Enjoy the convenience of a full-service catering solution for any occasion',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/service',
  },
  {
    title: 'Event Catering',
    subTitle:
      'Hosting a large gathering? Whether its for 10 or 100 guests, our catering service is designed to accommodate events of any scale, delivering traditional delights that please every palate',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/service',
  },
  {
    title: 'Daily Meals and Tiffins',
    subTitle:
      'For working individuals and seniors, we offer regular meal deliveries, ensuring timely, nutritious, and tasty food at your convenience',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/service',
  },
  {
    title: 'Special Occasion Catering',
    subTitle:
      'Celebrate your spiritual and traditional events with menus crafted to honor Brahmin culinary heritage. From small gatherings to grand celebrations, our food adds a touch of sanctity and joy',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/service',
  },
];

const featureListData = [
  {
    title: 'Authentic Brahmin Cuisine Right to Your Doorstep',
    subTitle:
      'Discover the sanctity of traditional flavors with us, where every dish is a meticulous masterpiece crafted by hands garbed in dhoti, bringing the heritage of Brahmin cuisine to the comfort of your home.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Customized Catering for Every Occasion',
    subTitle:
      'We promises a culinary journey tailored to the unique spirit of your celebrations, offering a seamless experience from the aroma of home-ground spices to the grandeur of event-specific feasts, all while upholding the venerable Brahmin culinary traditions.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Savor the Homely Touch of Nellore Best',
    subTitle:
      'Indulge in the soul-nourishing embrace of Nellore authentic home-style Brahmin cooking, where each meal is a heartfelt tribute to the time-honored recipes and the nurturing hands of the women who prepare them with an infusion of love and tradition.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Preserving Tradition in Every Bite',
    subTitle:
      'Every bite is an ancestral whisper, as we are committed to preserving the traditions of Brahmin cuisine, serving dishes steeped in ritual purity and crafted with the reverence deserving of our age-old culinary heritage.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: '2+', title: 'Years of experience' },
  { number: '10+', title: 'Co-workering Staff' },
  { number: '98%', title: 'Customer satisfaction rating' },
  { number: '100%', title: 'Vegetarian menu' },
  { number: '5000+', title: 'Meals served annually' },


];
const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Dr. James Lee, MD',
    designation: 'Head of Cardiologist',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'Dr. John Smith, MD',
    designation: 'Emergency Medicine Physician',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Dr. Susan Bones, MD',
    designation: 'Board-certified Pediatrician',
    description:
      'With experience in managing complex medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];
const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];

export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.jpg"
        imgUrl="/images/about/about_food_image.webp"
        title="About Us"
        subTitle="A home based business backed up by tradition"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="We have a wide range"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/why_dhoose_us.webp"
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Spacing md="90" />
    </>
  );
}
