import React from "react";
import Section from "../Section";
import Breadcrumb from "../Breadcrumb";
import Spacing from "../Spacing";
import Post from "../Post";
import { pageTitle } from "../../helpers/PageTitle";

const relatedBlog = [
  {
    title: "10 Easy and Delicious Vegan Recipes for Beginners",
    thumbUrl: "/images/blog/vegan_recipes.jpeg",
    date: "July 20",
    btnText: "Read More",
    href: "/blog/vegan-recipes",
  },
  {
    title: "A Guide to the Best Seasonal Fruits and Vegetables",
    thumbUrl: "/images/blog/seasonal_fruits.jpeg",
    date: "July 18",
    btnText: "Read More",
    href: "/blog/seasonal-fruits",
  },
  {
    title: "Top 5 Healthy Smoothie Recipes to Start Your Day",
    thumbUrl: "/images/blog/smoothie_recipes.jpeg",
    date: "July 15",
    btnText: "Read More",
    href: "/blog/smoothie-recipes",
  },
];

export default function BlogDetails() {
  pageTitle("Blog Details");
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="10 Easy and Delicious Vegan Recipes for Beginners" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_date">July 20, 2023</div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src="/images/blog/blog_details_1.jpeg"
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div>
            <div className="cs_blog_details">
              <h2>10 Easy and Delicious Vegan Recipes for Beginners</h2>
              <p>
                Adopting a vegan diet can seem challenging at first, but with
                these easy and delicious recipes, you'll find that it's quite
                manageable and incredibly tasty. From breakfast to dinner, these
                recipes will help you enjoy a variety of flavors while
                maintaining a healthy and balanced diet.
              </p>
              <ol>
                <li>
                  <b>Vegan Pancakes:</b>
                  <br />
                  Start your day with fluffy vegan pancakes made from simple
                  ingredients like flour, almond milk, and baking powder. Top
                  them with fresh fruits and maple syrup for a delightful
                  breakfast.
                </li>
                <li>
                  <b>Chickpea Salad Sandwich:</b>
                  <br />
                  This hearty sandwich is made with mashed chickpeas, vegan
                  mayo, and a variety of veggies. It's perfect for a quick lunch
                  or a picnic.
                </li>
                <li>
                  <b>Vegan Buddha Bowl:</b>
                  <br />
                  Combine quinoa, roasted veggies, avocado, and a tangy tahini
                  dressing for a nutritious and satisfying meal.
                </li>
                <li>
                  <b>Vegan Tacos:</b>
                  <br />
                  Fill soft tortillas with black beans, corn, salsa, and avocado
                  for a flavorful and easy-to-make dinner.
                </li>
                <li>
                  <b>Chocolate Avocado Mousse:</b>
                  <br />
                  This creamy dessert is made with ripe avocados, cocoa powder,
                  and a touch of maple syrup. It's a healthy and indulgent way
                  to end your meal.
                </li>
              </ol>
              <p>
                Each of these recipes is not only easy to prepare but also
                packed with nutrients. Whether you're new to veganism or looking
                to add more plant-based meals to your diet, these recipes are a
                great place to start. Enjoy experimenting with different
                ingredients and flavors to make these dishes your own.
              </p>
              <h2>Tips for a Successful Vegan Diet</h2>
              <p>
                Transitioning to a vegan diet can be smooth and enjoyable with
                the right approach. Here are some tips to help you succeed:
              </p>
              <ul>
                <li>
                  <h3>1. Plan Your Meals</h3>
                  Planning your meals ahead of time ensures that you have all
                  the necessary ingredients and helps you avoid last-minute
                  unhealthy choices.
                </li>
                <li>
                  <h3>2. Stay Informed</h3>
                  Educate yourself about vegan nutrition to ensure you're
                  getting all essential nutrients. Include a variety of fruits,
                  vegetables, grains, and legumes in your diet.
                </li>
                <li>
                  <h3>3. Find Support</h3>
                  Joining vegan communities or finding a friend to share the
                  journey with can provide motivation and support.
                </li>
                <li>
                  <h3>4. Experiment with Recipes</h3>
                  Don't be afraid to try new recipes and ingredients. The more
                  you experiment, the more you'll discover delicious vegan
                  meals.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Spacing md="55" />
      </div>
      <Spacing md="55" />
      <div className="container">
        <h2>Related Blogs</h2>
        <div className="row">
          {relatedBlog.map((blog, index) => (
            <div key={index} className="col-md-4">
              <Post
                title={blog.title}
                thumbUrl={blog.thumbUrl}
                date={blog.date}
                btnText={blog.btnText}
                href={blog.href}
              />
            </div>
          ))}
        </div>
        <Spacing md="55" />
      </div>
      <Spacing md="55" />
    </>
  );
}
