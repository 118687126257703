import React from 'react';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
const blogData = [
  {
    title:
      'Savoring Tradition: A Journey Through Andhra Culinary Heritage',
    thumbUrl: 'images/blog/post_1.jpeg',
    date: 'March 12',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: `From Our Hearth to Yours: Stories Behind Our Andhra Catering Legacy`,
    thumbUrl: 'images/blog/post_2.jpeg',
    date: 'March 11',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'The Secret Ingredients: Unveiling the Magic of Andhra Cuisine',
    thumbUrl: 'images/blog/post_3.jpeg',
    date: 'March 9',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'A Taste of Home: Celebrating Andhras Rich Food Culture',
    thumbUrl: 'images/blog/post_4.jpeg',
    date: 'March 8',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Heirloom Recipes: Preserving the Flavors of Andhra',
    thumbUrl: 'images/blog/post_5.jpeg',
    date: 'March 5',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Culinary Chronicles: The Evolution of Andhra Catering Through Generations',
    thumbUrl: 'images/blog/post_6.jpeg',
    date: 'March 2',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  
];

export default function Blog() {
  pageTitle('Blog');
  return (
    <><BannerSectionStyle5
    bgUrl="/images/contact/banner_bg.jpg"
    imgUrl="/images/about/about_food_image.webp"
    title="Blogs"
    subTitle="Recipes and stories from generations past"
  />
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Our Latest Blogs" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      
    </>
  );
}
