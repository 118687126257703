import React from "react";
import HeroStyle2 from "../Hero/HeroStyle2";
import Section from "../Section";
import AboutSectionStyle3 from "../Section/AboutSection/AboutSectionStyle";
import TestimonialSectionStyle2 from "../Section/TestimonialSection/TestimonialSectionStyle2";
import WorkingProcess from "../Section/WorkingProcess";
import BannerSectionStyle2 from "../Section/BannerSection/BannerSectionStyle2";
import BlogSectionStyle3 from "../Section/BlogSection/BlogSectionStyle3";
import FaqSectionStyle2 from "../Section/FaqSection/FaqSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import ServiceSection from "../Section/ServiceSection/ServiceSection";
import {
  Service,
  testimonialData,
  workingProcessData,
  blogData,
  faqData,
} from "../../constant/data.js";


export default function HomeStyle2() {
  pageTitle("Bramhanasvilas");
  return (
    <>
      <HeroStyle2
        title="Welcome to Bramhanavilas"
        subTitle="We honor the age-old Brahmin tradition of sattvic (pure) vegetarian cooking"
        bgUrl="/images/home_2/slide01.jpg"
        imgUrl="/images/heading-icon.svg"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/cM0sMrX-8qQ"
        btnText="About Us"
        btnUrl="/"
        funfactList={[
          { number: "2+", title: "Years of experience" },
          { number: "98%", title: "Customer satisfaction rating" },
          { number: "100%", title: "Vegetarian menu" },
          { number: "5000+", title: "Meals served annually" },
        ]}
      />
      <Section bottomMd={190} bottomLg={45} bottomXl={105}>
        <AboutSectionStyle3
          titleUp="Our Services"
          title="Tailored Catering Solutions for Every Need"
          subTitle="Discover catering solutions tailored to your every need. Whether it's an small gathering or a grand celebration, our services cater to all occasions. With meticulous attention to detail and culinary expertise, we create unforgettable dining experiences that exceed expectations. Elevate your event with our personalized touch and exceptional cuisine"
          imgUrl="/images/departments/indian-food-640.jpg"
        />
      </Section>

      <Section
        className="cs_bg_filed"
        style={{ backgroundImage: `url(/images/home_2/testimonial_bg.svg)` }}
        topMd={190}
        topLg={45}
        topXl={105}
        bottomMd={200}
        bottomLg={20}
        bottomXl={110}
      >
        <ServiceSection
          sectionTitle="Our Top Services"
          sectionTitleUp="Services"
          data={Service}
        />
      </Section>
      <Section
        className="cs_bg_filed"
        style={{ backgroundImage: `url(/images/home_2/testimonial_bg.svg)` }}
        topMd={190}
        topLg={45}
        topXl={105}
        bottomMd={10}
        bottomLg={10}
        bottomXl={110}
      >
        <TestimonialSectionStyle2
          sectionTitle="What Our Clients Say"
          sectionTitleUp="TESTIMONIALS"
          data={testimonialData}
        />
      </Section>

      <Section
        topMd={185}
        topLg={40}
        topXl={100}
        bottomMd={200}
        bottomLg={45}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle="How it Works"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      <Section>
        <BannerSectionStyle2
          bgUrl="/images/home_2/indian-veg-thali.avif"
          title="Get ready for the Taste of Tradition"
          subTitle="Contact or Book an Appointemnt <br />
          with us to experience authentic dishes"
        />
      </Section>
      <Section topMd={190} topLg={45} topXl={105}>
        <BlogSectionStyle3
          sectionTitle="Our Latest Updates"
          sectionTitleUp="BLOG POSTS"
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogData}
        />
      </Section>
      {/* Start FAQ Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <FaqSectionStyle2
          data={faqData}
          sectionTitle="Usually Ask"
          sectionTitleUp="What People"
        />
      </Section>
    </>
  );
}
