import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import BookingForm from "../Form";

function PopUpModal({ isBookModalOpen, onBookModalClose, name, id }) {
  return (
    <Modal isOpen={isBookModalOpen} onClose={onBookModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BookingForm close={onBookModalClose} id={id} service_name={name} />
        </ModalBody>
        {/* <ModalFooter>
          <Button onClick={onBookModalClose}>Close</Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}

export default PopUpModal;
