import React from "react";
import { useParams } from "react-router-dom";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import Section from "../Section";
import ServiceDetailsSection from "../Section/ServiceDetailsSection";
import { pageTitle } from "../../helpers/PageTitle";
import { Service } from "../../constant/data.js"; // Adjust path as per your project structure
import ErrorPage from "./ErrorPage.jsx";

export default function ServiceDetails() {
  const { serviceId } = useParams();

  // Find the service data based on serviceId
  const service = Service.find((item) => item.id === serviceId);

  if (!service) {
    return <ErrorPage/>;
  }

  pageTitle(`${service.name} Service Details`);

  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <ServiceDetailsSection
          bgUrl="/images/doctors/doctor_details_bg.svg" // Replace with appropriate background URL
          imgUrl={service.imgUrl}
          name={service.name}
          department={service.department}
          designation={service.designation}
          description={service.description}
          social={service.social}
          contactInfo={service.contactInfo} // Ensure contactInfo is added to your Service data
          contactInfoHeading="Contact Info"
          schedules={service.schedules} // Ensure schedules is added to your Service data
          scheduleHeading="Availability"
          experiences={service.experiences} // Ensure experiences is added to your Service data
          experiencesHeading="Speciality"
          awards={service.awards}
          awardHeading="Awards/Achievements"
          id={serviceId}
        />
      </Section>
    </>
  );
}
