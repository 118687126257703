import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import HomeStyle2 from './components/Pages/Home';
import About from './components/Pages/About';
import Blog from './components/Pages/Blog';
import BlogDetails from './components/Pages/BlogDetails';
import Gallery from './components/Pages/Gallery';
import Contact from './components/Pages/Contact';
import { useEffect } from 'react';
import ErrorPage from './components/Pages/ErrorPage';
import ServicePage from './components/Pages/ServicePage';
import ServiceDetails from './components/Pages/ServiceDetails';
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomeStyle2 />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<ServicePage />} />
        <Route path="service/:serviceId" element={<ServiceDetails />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:blogId" element={<BlogDetails />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact" element={<Contact />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
