import React from "react";
import Section from "../Section";
import TeamSectionStyle2 from "../Section/TeamSection/TeamSectionStyle2";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import Spacing from "../Spacing";
import { Service } from "../../constant/data.js";
export default function ServicePage() {
  const hideOnMobile = {
    display: "block", // Display by default
    "@media only screen and (max-width: 768px)": {
      display: "none", // Hide section on mobile devices
    },
  };

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.jpg"
        imgUrl="/images/about/about_food_image.webp"
        title="Services"
        subTitle="We provide wide range of services"
      />
      <Section topMd={65} bottomMd={0} bottomLg={150} bottomXl={0}>
        <TeamSectionStyle2 data={Service} />
      </Section>
      <div className="custom_space">
        <Spacing md="170" />
      </div>
    </>
  );
}
