export const Service = [
    {
        imgUrl: "/images/departments/indian-food-640.jpg",
        department: "Home Services",
        name: "Traditional Home Cooking",
        designation: "Brahmin Chef",
        description:
            "Experience the expertise of our Brahmin chef in traditional dhoti at your home. We provide skilled cooks to prepare authentic Brahmin meals right in your kitchen, ensuring a personalized and spiritual dining experience.",
        social: [
            { icon: "fa6-brands:facebook-f", href: "/about" },
            { icon: "fa6-brands:linkedin-in", href: "/about" },
            { icon: "fa6-brands:twitter", href: "/about" },
        ],
        category: "Home Services",
        href: "/service/1",
        contactInfo: [
            { iconUrl: "/images/icons/call.svg", title: "+91-863-915-4925" },
            {
                iconUrl: "/images/icons/envlope.svg",
                title: "bramhanavilas@gmail.com",
            },
        ],
        schedules: [
            { day: "Monday", time: "08.00-20.00" },
            { day: "Tuesday", time: "08.00-20.00" },
            { day: "Wednesday", time: "08.00-20.00" },
            { day: "Thursday", time: "08.00-20.00" },
            { day: "Friday", time: "08.00-20.00" },
        ],
        experiences: [
            {
                title:
                    "Expertise in preparing traditional Brahmin meals, focusing on authentic flavors and cultural authenticity.",
            },
            {
                title:
                    "Experience in providing personalized home cooking services, tailored to individual tastes and dietary preferences.",
            },
            {
                title:
                    "Dedicated to creating a homely dining experience with meals prepared fresh in your own kitchen.",
            },
        ],
        awards: [
            {
                title:
                    "Recognized for excellence in home cooking and maintaining high standards of culinary authenticity.",
            },
            {
                title:
                    "Praised by clients for personalized service, attention to detail, and exceptional meal quality.",
            },
        ],
        id: "1",
    },
    {
        imgUrl: "/images/departments/indian-food-640.jpg",
        department: "Daily Meals and Tiffins",
        name: "Daily Meals and Tiffin Service",
        designation: "Tiffin Service",
        description:
            "For working individuals and seniors, we offer regular meal deliveries, ensuring timely, nutritious, and tasty food at your convenience. Enjoy authentic Brahmin meals delivered fresh to your doorstep every day.",
        social: [
            { icon: "fa6-brands:facebook-f", href: "/about" },
            { icon: "fa6-brands:linkedin-in", href: "/about" },
            { icon: "fa6-brands:twitter", href: "/about" },
        ],
        category: "Daily Meals and Tiffins",
        href: "/service/2",
        contactInfo: [
            { iconUrl: "/images/icons/call.svg", title: "+91-863-915-4925" },
            {
                iconUrl: "/images/icons/envlope.svg",
                title: "bramhanavilas@gmail.com",
            },
        ],
        schedules: [
            { day: "Monday", time: "08.00-20.00" },
            { day: "Tuesday", time: "08.00-20.00" },
            { day: "Wednesday", time: "08.00-20.00" },
            { day: "Thursday", time: "08.00-20.00" },
            { day: "Friday", time: "08.00-20.00" },
        ],
        experiences: [
            {
                title:
                    "Experienced in providing nutritious and balanced meals suitable for various dietary needs and preferences.",
            },
            {
                title:
                    "Known for reliable and punctual delivery services, ensuring meals are fresh and ready to enjoy.",
            },
        ],
        awards: [
            {
                title:
                    "Recognized for excellence in daily meal services, catering to diverse nutritional requirements and tastes.",
            },
            {
                title:
                    "Praised by clients for consistent quality, taste, and customer-centric meal planning.",
            },
        ],
        id: "2",
    },
    {
        imgUrl: "/images/departments/indian-food-640.jpg",
        department: "Catering Service",
        name: "Special Occasion Catering",
        designation: "Event Catering",
        description:
            "Celebrate your spiritual and traditional events with menus crafted to honor Brahmin culinary heritage. From small gatherings to grand celebrations, our food adds a touch of sanctity and joy, ensuring a memorable dining experience for all guests.",
        social: [
            { icon: "fa6-brands:facebook-f", href: "/about" },
            { icon: "fa6-brands:linkedin-in", href: "/about" },
            { icon: "fa6-brands:twitter", href: "/about" },
        ],
        category: "Catering Service",
        href: "/service/3",
        contactInfo: [
            { iconUrl: "/images/icons/call.svg", title: "+91-863-915-4925" },
            {
                iconUrl: "/images/icons/envlope.svg",
                title: "bramhanavilas@gmail.com",
            },
        ],
        schedules: [
            { day: "Monday", time: "08.00-20.00" },
            { day: "Tuesday", time: "08.00-20.00" },
            { day: "Wednesday", time: "08.00-20.00" },
            { day: "Thursday", time: "08.00-20.00" },
            { day: "Friday", time: "08.00-20.00" },
        ],
        experiences: [
            {
                title:
                    "Expertise in crafting bespoke menus that reflect the cultural and spiritual significance of traditional celebrations.",
            },
            {
                title:
                    "Known for delivering impeccable service and creating memorable dining experiences for all guests.",
            },
        ],
        awards: [
            {
                title:
                    "Awarded for excellence in special occasion catering, providing unique and authentic culinary experiences.",
            },
            {
                title:
                    "Praised by clients for attention to detail, professionalism, and dedication to preserving culinary traditions.",
            },
        ],
        id: "3",
    }
];

export const testimonialData = [
    {
        text: "Discovering Bramhanavilas has been a revelation for me and my family. The flavors are authentic, reminding us of home with every bite. A huge thanks to the team for their dedication to authenticity and quality.",
        ratingNumber: "5",
        avatarImgUrl: "/images/home_1/avatar_2.png",
        avatarName: "Rajesh",
        avatarDesignation: "Happy Customer",
    },
    {
        text: "I recently had the pleasure of trying out Bramhanavils's catering service for a family event, and I was blown away by the quality and taste of the food. It's clear that there's a lot of love and tradition that goes into their cooking.",
        ratingNumber: "4.5",
        avatarImgUrl: "/images/home_1/avatar_2.png",
        avatarName: "Lakshmi Narayana",
        avatarDesignation: "Happy Customer",
    },
    {
        text: "Bramhanavilas has quickly become my go-to for traditional Andhra food. The attention to detail and the use of fresh, high-quality ingredients make every meal a delightful experience. Highly recommended!",
        ratingNumber: "5",
        avatarImgUrl: "/images/home_1/avatar_2.png",
        avatarName: "Anitha",
        avatarDesignation: "Happy Customer",
    },
];

export const workingProcessData = [
    {
        title: "Place Your Order",
        subTitle:
            "You can place your order with us by <br />calling our office, visiting our website, or <br /> by submitting contact form",
        iconUrl: "/images/home_2/2.png",
        number: "01",
    },
    {
        title: "Delivery or Pickup",
        subTitle:
            "Choose a delivery or pickup<br /> for your order. Our team will ensure <br />that your food is ready on time.",
        iconUrl: "/images/home_2/wording_process_icon_1.svg",
        number: "02",
    },
    {
        title: "Enjoy Your Meal",
        subTitle:
            "Once you receive your meal, it's time to <br />enjoy your delicious meal prepared with <br />care by our culinary experts.",
        iconUrl: "/images/home_2/3.png",
        number: "03",
    },
    {
        title: "Feedback and Follow-up",
        subTitle:
            "We value your feedback! Let us know <br />how we did and if there's anything we can <br />do to improve your experience.",
        iconUrl: "/images/home_2/4.png",
        number: "04",
    },
    {
        title: "Subscription Management",
        subTitle:
            "For meal and tiffins subscribers, manage <br />your subscription easily through our <br />website or by contacting us",
        iconUrl: "/images/home_2/5.png",
        number: "05",
    },
];

export const blogData = [
    {
        title: "Savoring Tradition: A Journey Through Andhra's Culinary Heritage",
        thumbUrl: "images/home_1/post_1.jpeg",
        date: "March 1, 2024",
    },
    {
        title: "From Our Hearth to Yours: Stories Behind Our Andhra Catering Legacy",
        thumbUrl: "images/home_1/post_2.jpeg",
        date: "March 1, 2024",
    },
    {
        title: "The Secret Ingredients: Unveiling the Magic of Andhra Cuisine",
        thumbUrl: "images/home_1/post_3.jpeg",
        date: "March 1, 2024",
    },
];

export const faqData = [
    {
        title: "What kind of traditional cuisine do you specialize in?",
        content: "We focus on the rich culinary heritage of Andhra. Our menu highlights authentic dishes passed down through generations, featuring the unique flavors and techniques of our ancestors.",
    },
    {
        title: "Can you accommodate dietary restrictions?",
        content: "Yes, we understand the importance of dietary needs.  We can adapt many of our traditional recipes to be vegetarian, vegan, or gluten-free. Please let us know your specific requirements when placing your order. ",
    },
    {
        title: "Do you offer delivery, pickup, or both?",
        content: "We offer both delivery and pick-up options. Delivery fees may apply based on your location. Please discuss your preferences with us when you inquire about our services.",
    },
    {
        title: "What is your minimum order size for catering?",
        content: "Our minimum order size varies depending on the menu items selected. However, we cater for events of all sizes, from small gatherings to large celebrations. Contact us for a personalized quote.",
    },
    {
        title: "How should I place a catering order and how much notice do you need?",
        content: "You can place your catering order by contacting us via phone or our website's contact form.   Ideally, a minimum of 2 days notice allows us to source the freshest ingredients and provide the best service.",
    },
];
